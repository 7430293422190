/* eslint class-methods-use-this: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import NavComponent from './NavComponent';

class WrapperComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modelPath: props.modelPath,
      mobileNavOpenState: false,
      loading: true,
      error: false,
    };

    this.onWindowResize = this.onWindowResize.bind(this);
    this.onHistoryPopstate = this.onHistoryPopstate.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.getWindowSize = this.getWindowWidth.bind(this);
    this.onNavClickCallback = this.onNavClickCallback.bind(this);

    this.WINDOW_RESIZE_TIMEOUT = null;
  }

  async componentDidMount() {
    this.getModel();
    window.addEventListener('popstate', this.onHistoryPopstate);
    window.addEventListener('resize', this.onWindowResize);
  }

  componentWillUnmount() {
    clearTimeout(this.WINDOW_RESIZE_TIMEOUT);
    window.removeEventListener('resize', this.onWindowResize);
  }

  onWindowResize() {
    let throttled = false;
    if (!throttled) {
      throttled = true;
      // eslint-disable-next-line no-return-assign
      this.WINDOW_RESIZE_TIMEOUT = setTimeout(() => (throttled = true), 500);

      const width = this.getWindowWidth();
      this.setState({ width });
    }
  }

  onHistoryPopstate() {
    const modelPath = document.location.pathname.indexOf('index.html') !== -1 ? `${document.location.pathname}.json` : `${document.location.pathname}index.html.json`;
    this.setState({ modelPath }, this.getModel);
  }

  onMenuClick(e) {
    e.preventDefault();
    const { mobileNavOpenState } = this.state;
    this.setState({
      mobileNavOpenState: !mobileNavOpenState,
    });
  }

  onNavClickCallback(newUrl) {
    try {
      window.history.pushState({}, '', newUrl);
      const newModelPath = `${newUrl}index.html.json`;
      this.setState({
        modelPath: newModelPath,
      }, this.getModel);
    } catch (err) {
      this.setState({ error: err, loading: false });
    }
  }

  async getModel() {
    const { modelPath } = this.state;
    const width = this.getWindowWidth();
    const route = window.location.pathname.replace('index.html', '');

    try {
      const requestModel = await axios.get(modelPath);
      const { template, ...model } = requestModel.data;
      const Template = await import(`./templates/${template}Component.jsx`);
      this.setState({
        Template: Template.default, model, width, route, loading: false,
      });
    } catch (err) {
      this.setState({ error: err, loading: false });
    }
  }

  getWindowWidth() {
    const documentElement = { document };
    const body = document.getElementsByTagName('body')[0];
    const width = window.innerWidth || documentElement.clientWidth || body.clientWidth;

    return width;
  }

  render() {
    const {
      error, loading, model, Template, width, route, mobileNavOpenState,
    } = this.state;

    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return <h1>Sorry, an error has occurred</h1>;
    }

    if (loading) {
      return null;
    }

    const { date, description, title } = model;
    // This is not very React~y but /shrug
    const titleTag = document.querySelectorAll('title');
    const metaDescription = document.querySelectorAll('meta[name="description"]');
    const metaDate = document.querySelectorAll('meta[name="date"]');

    titleTag[0].innerText = title;
    metaDescription[0].content = description;
    if (metaDate.length) {
      metaDate[0].content = date;
    }

    return (
      <div>
        {width < 480 && <div className={mobileNavOpenState ? null : 'dn'}><NavComponent route={route} width={width} clickCallback={this.onNavClickCallback} /></div>}
        <div className="pa3 pa4-ns">
          <div className="flex-ns">
            <div className="w-25-ns">
              <div className="flex justify-between flex-none-ns db-ns">
                <h1 className="w-50 sans-serif w-100-ns tracked"><a href="/" className="link dark-gray ttu f3">Ann Liao</a></h1>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                {width < 480 && <a href="#menu" onClick={this.onMenuClick} className="mt4 mb4 db w-25 tr link light-silver sans-serif">Menu</a>}
                {width > 480 && (
                  <NavComponent
                    route={route}
                    width={width}
                    clickCallback={this.onNavClickCallback}
                  />
                )}
              </div>
            </div>
            <div className="w-75-ns">
              <Template model={model} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

WrapperComponent.propTypes = {
  modelPath: PropTypes.string.isRequired,
};

export default WrapperComponent;
