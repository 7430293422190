import React from 'react';
import PropTypes from 'prop-types';

class NavComponent extends React.Component {
  constructor(props) {
    super(props);
    this.onNavClick = this.onNavClick.bind(this);
  }

  onNavClick(e) {
    e.preventDefault();
    const { clickCallback } = this.props;
    const { href } = e.target;
    const newUrl = `${href.replace('index.html', '')}${href.endsWith('/') ? '' : '/'}`;
    clickCallback(newUrl);
  }

  getClassName(link) {
    const { width, route } = this.props;
    if (width < 480) {
      return route === link ? 'white' : 'moon-gray hover-near-white';
    }
    return route === link ? 'moon-gray' : 'near-black hover-moon-gray';
  }

  render() {
    const { width } = this.props;
    return (
      <nav className={`${width < 480 ? 'bg-light-silver' : 'bg-white'} w-100-ns`}>
        <ul className="tc sans-serif ma0 pa0 list tl-ns">
          <li>
            <a href="/flow/" onClick={this.onNavClick} className={`${width < 480 ? 'moon-gray hover-near-white' : 'near-black hover-moon-gray'} ttu link db di-ns pt3 pt0-ns pb3 pb0-ns mr6 mr0-ns ml6 ml0-ns bb b-near-white bn-ns`}>Works</a>
            <ul className="ma0 pa0 list ml3-ns mt3-ns mr0-ns mb3-ns pa0-ns">
              <li><a href="/flow/" onClick={this.onNavClick} className={`${this.getClassName('/flow/')} link db pt3 pb3 mr6 ml6 di-ns pt0-ns pb0-ns mr0-ns ml0-ns`}>Flow</a></li>
              <li><a href="/inked/" onClick={this.onNavClick} className={`${this.getClassName('/inked/')} link db pt3 pb3 mr6 ml6 di-ns pt0-ns pb0-ns mr0-ns ml0-ns`}>Inked</a></li>
              <li><a href="/soliloquy/" onClick={this.onNavClick} className={`${this.getClassName('/soliloquy/')} link db pt3 pb3 mr6 ml6 di-ns pt0-ns pb0-ns mr0-ns ml0-ns`}>Soliloquy</a></li>
            </ul>
          </li>
          <li><a href="/about" onClick={this.onNavClick} className={`${this.getClassName('/about/')} ttu link db pt3 pb3 mr6 ml6 di-ns pt0-ns pb0-ns mr0-ns ml0-ns`}>CV</a></li>
        </ul>
      </nav>
    );
  }
}

NavComponent.propTypes = {
  route: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  clickCallback: PropTypes.func.isRequired,
};

export default NavComponent;
